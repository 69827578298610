import { isBrowser } from '@builder.io/sdk';
import React, { useRef, useState } from 'react';
import { Layout } from '../../components/layout/layout';
import Btn from '../../components/material/btn/btn';
import Card from '../../components/material/card/card';
import * as styles from './signature.module.css';

const INPUTS = Object.entries({
    name: 'text',
    role: 'text',
    mug_url: 'url',
    link_url: 'url',
    link_image_url: 'url',
    email: 'email',
    phone: 'tel'
});

const CSS = `
@import url("https://use.typekit.net/kze5jms.css");

.signature {
    font-family: filson-pro, sans-serif;
}

.signature-module--signature-headshot--nn4\+E {
    border-radius: 0.25rem;
}

.signature-module--signature-info-block--ghkmo {
    padding-left: 1.5rem;
}

.signature-module--signature-one-liner--e9Z8I {
    padding: 1rem;
}

.signature-module--signature-one-liner--e9Z8I div {
    border-radius: 0.125rem;
    border-left: 4px solid #59479d;
    padding-left: 0.5rem;
}

.signature-module--signature-logo--ESpd4 {
    height: 5rem;
    padding-bottom: 1.5rem;
}

.signature-module--underline--lwAme {
    text-decoration: underline;
}

.signature-module--text-name--Bts9- {
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 700;
    line-height: 1;
    --tw-text-opacity: 1;
    color: #59479d;
}

.signature-module--text-role--B0auJ {
    margin-top: 0px;
    padding-bottom: 1rem;
    font-size: 1.125rem;
    line-height: 1.75rem;
    --tw-text-opacity: 1;
    color: #59479d;
}

.signature-module--link--3Zs3B {
    margin-bottom: 0.25rem;
    display: block;
    font-size: 0.875rem;
    line-height: 1.25rem;
    --tw-text-opacity: 1;
    color: #59479d;
    text-decoration-line: underline;
    text-decoration: underline;
}

.signature-module--link--3Zs3B svg {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    display: inline-block;
}

.signature-module--text-small--W24ol {
    font-size: 0.875rem;
    line-height: 1.25rem;
}`;

export default function SignaturePage({ location }) {
    const params = new URLSearchParams(location.search);
    const prefill = Object.fromEntries(INPUTS.map(([name]) => [name, params.get(name)]));
    const [state, setState] = useState(prefill);

    const handleInputChange = (e) => {
        const target = e.target;
        const key = target.name;
        const value = target.value;
        setState({
            ...state,
            [key]: value
        });
    }

    const inputs = INPUTS.map(([name, type], i) => <input
        key={i}
        className='bg-slate-300 p-2 rounded block my-2'
        type={type}
        value={state[name]}
        placeholder={name}
        name={name}
        onChange={handleInputChange}
    />)

    const signatureRef = useRef();
    const signature = <table ref={signatureRef} className='signature'>
        <tbody>
            <tr>
                <td width='200px'>
                    <img width='200px' className={styles.signatureHeadshot} src={state.mug_url} alt={`Portrait of ${state.name}`}></img>
                </td>
                <td className={styles.signatureInfoBlock}>
                    <a href='https://purposefront.com'>
                        <img className={styles.signatureLogo} height="800px" src="https://purposefront.com/logo-stacked.svg" alt="Purpose Front Logo"/>
                    </a>
                    <div className={styles.textName}>{state.name}</div>
                    <div className={styles.textRole}>
                        {state.role} at <a className={styles.underline} href='https://purposefront.com'>Purpose Front</a>
                    </div>
                    <a className={styles.link} href={`mailto:${state.email}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M2 2A2 2 0 0 0 .05 3.555L8 8.414l7.95-4.859A2 2 0 0 0 14 2H2Zm-2 9.8V4.698l5.803 3.546L0 11.801Zm6.761-2.97-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 9.671V4.697l-5.803 3.546.338.208A4.482 4.482 0 0 1 12.5 8c1.414 0 2.675.652 3.5 1.671Z"/>
                            <path d="M15.834 12.244c0 1.168-.577 2.025-1.587 2.025-.503 0-1.002-.228-1.12-.648h-.043c-.118.416-.543.643-1.015.643-.77 0-1.259-.542-1.259-1.434v-.529c0-.844.481-1.4 1.26-1.4.585 0 .87.333.953.63h.03v-.568h.905v2.19c0 .272.18.42.411.42.315 0 .639-.415.639-1.39v-.118c0-1.277-.95-2.326-2.484-2.326h-.04c-1.582 0-2.64 1.067-2.64 2.724v.157c0 1.867 1.237 2.654 2.57 2.654h.045c.507 0 .935-.07 1.18-.18v.731c-.219.1-.643.175-1.237.175h-.044C10.438 16 9 14.82 9 12.646v-.214C9 10.36 10.421 9 12.485 9h.035c2.12 0 3.314 1.43 3.314 3.034v.21Zm-4.04.21v.227c0 .586.227.8.581.8.31 0 .564-.17.564-.743v-.367c0-.516-.275-.708-.572-.708-.346 0-.573.245-.573.791Z"/>
                        </svg>
                        { state.email }
                    </a>
                    <a className={styles.link} href={`tel:${state.phone}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone-fill" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                        </svg>
                        { state.phone }
                    </a>
                </td>
            </tr>
            <tr>
                <td colSpan='2' className={styles.signatureOneLiner}>
                    <div>
                    Shopping at Purpose Front lets you support causes that are important to you. Your purchase is more than just a T-shirt – it's an investment into something that matters! Every purchase you make goes towards positive impact!
                    </div>
                    <br />
                    <a className={styles.link} href="https://purposefront.com">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M11.5 4v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5ZM8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1Zm0 6.993c1.664-1.711 5.825 1.283 0 5.132-5.825-3.85-1.664-6.843 0-5.132Z"/>
                        </svg>
                        Wanna' support some incredible causes?
                    </a>
                    <a className={styles.link} href="https://purposefront.com/partners">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6-.097 1.016-.417 2.13-.771 2.966-.079.186.074.394.273.362 2.256-.37 3.597-.938 4.18-1.234A9.06 9.06 0 0 0 8 15Zm0-9.007c1.664-1.711 5.825 1.283 0 5.132-5.825-3.85-1.664-6.843 0-5.132Z"/>
                        </svg>
                        Wanna' raise funds for your impactful cause?
                    </a>
                </td>
            </tr>
            { state.link_image_url != null && <tr>
                <td colSpan="2">
                    <a href={state.link_url}>
                        <img style={{ height: '100px', marginTop: '0.75em' }} src={state.link_image_url} alt={`${state.name}'s url`}/>
                        <br/>
                        <small>(scan to transfer contact info to phone)</small>
                    </a>
                </td>
            </tr> }
        </tbody>
    </table>

    const copySignature = () => {
        const el = signatureRef.current;
        const type = 'text/html';
        const html = new Blob([`${el.outerHTML} <style>${CSS}</style>`], { type });
        const item = new ClipboardItem({ [type]: html });
        navigator.clipboard.write([item]);
    }

    if (process.env.NODE_ENV !== 'development') return null;

    return <Layout>
        <div className='grid grid-cols-2'>
            <div>
                {inputs}
            </div>
            <div>
                <Card className='mb-2 p-4'>
                    {signature}
                </Card>
                <Btn onClick={copySignature} className='w-full block bg-slate-900 text-white' icon='bi-clipboard-plus-fill'>
                    Copy to Clipboard
                </Btn>
            </div>
        </div>
    </Layout>
}